import { useEffect, useState } from 'react';
import { concat, without } from 'lodash';

export default (onSelectedKeysChange) => {
  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleSelect = (selectedKey) =>
    setSelectedKeys(concat(selectedKeys, [selectedKey]));

  const handleDeselect = (deselectedKey) =>
    setSelectedKeys(without(selectedKeys, deselectedKey));

  const clearSelection = () => setSelectedKeys([]);

  useEffect(() => onSelectedKeysChange(selectedKeys), [selectedKeys]);

  return {
    selectedKeys,
    setSelectedKeys,
    handleSelect,
    handleDeselect,
    clearSelection,
  };
};

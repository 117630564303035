import React, { useEffect, useState, useCallback } from 'react';
import { debounce, keyBy } from 'lodash';
import {
  createCustomAudience,
  getCustomAudience,
  updateCustomAudience,
} from 'api/customAudiencesAPI';
import { getChannels } from 'api/integrationsAPI';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomerListLoading,
  getCustomerListTotalCount,
  getProductRecommendations,
  getProductRecommendationsIsLoading,
  getSegmentsCount,
} from 'store/customerList/getters';
import { ValidationError } from 'constants/errors';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import validation from 'components/views/CustomAudience/audienceValidation';
import { fetchCustomerList, fetchProductRecommendations } from 'store/customerList/customerListSlice';
import { Box, Grid, Stack } from '@mui/material';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import Input from 'components/common/Input';
import StatusSwitch from 'components/common/StatusSwitch';
import classNames from 'classnames';
import { ReactComponent as DeleteIcon } from 'assets/svg/trash.svg';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';
import CustomerListSegments from 'components/common/CustomersListTools/CustomerListSegments';
import ContentLoader from 'components/common/ContentLoader';
import AudienceChannels from 'components/views/CustomAudience/AudienceChannels';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import ShowCustomersTableWindow from 'components/views/CustomAudience/ShowCustomersTableWindow';
import DeleteAudiencePreviewModal from 'components/views/CustomAudience/DeleteAudiencePreviewModal';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import 'components/views/CustomAudience/styles.scss';
import { GUIDES } from 'constants/guides';
import { setAudienceName } from 'store/crumbsIdToName/crumbsIdToNameSlice';
import AudienceTypeSelector from 'components/views/CustomAudience/AudienceTypeSelector';
import tagHelper from 'components/common/AdvancedFilterTag/tagHelper';
import {
  CUSTOMER_DETAILS, CUSTOMER_EVENTS,
  CUSTOMER_PERFORMANCE,
} from 'constants/entities';
import { exportCustomers, getCustomerFilterList } from 'api/customersAPI';
import AdvancedFilterTag from 'components/common/AdvancedFilterTag';
import SearchEntity from 'components/common/SearchEntity';
import { ToggleSearchEntity } from 'components/common/TableSearchEntity';
import {
  CUSTOMER_DETAILS_FIELDS,
  CUSTOMER_EVENT_FIELDS,
  CUSTOMER_PERFORMANCE_FIELDS,
} from 'components/common/SearchEntity/constants';
import Slider from 'components/common/Slider';
import { useToast } from 'components/common/Toast';
import Disclaimer from 'components/common/Disclaimer';
import { getTagsFromFilters, prepareTagsAsFilterArray } from 'utils/filters';
import { AUDIENCE_STATUSES, AUDIENCE_STATUSES_VALUES, AUDIENCE_TYPES } from 'constants/customAudience';
import RecommendedProducts from 'components/views/CustomAudience/RecommendedProducts';
import multiSelectHelper from 'components/common/MultiSelect/multiSelectHelper';
import { NumberFormatted } from 'helper/NumberFormatted';
import DataExport from 'components/common/DataExport';
import Divider from 'components/common/Divider';
import StatusIndicator from 'components/common/StatusIndicator';
import IconButton from "../../common/IconButton";

const searchEntityConfig = [{
  key: CUSTOMER_PERFORMANCE,
  title: MESSAGES.performance,
  hasPages: false,
  unique: false,
}, {
  key: CUSTOMER_DETAILS,
  title: MESSAGES.details,
  hasPages: false,
  unique: false,
}, {
  key: CUSTOMER_EVENTS,
  title: MESSAGES.events,
  hasPages: false,
  unique: false,
}];

const AUDIENCE_ITEMS = {
  name: 'name',
  is_active: 'is_active',
};

const entityKeysToFields = [
  {
    entityKey: CUSTOMER_PERFORMANCE,
    fields: CUSTOMER_PERFORMANCE_FIELDS,
  },
  {
    entityKey: CUSTOMER_DETAILS,
    fields: CUSTOMER_DETAILS_FIELDS,
  },
  {
    entityKey: CUSTOMER_EVENTS,
    fields: CUSTOMER_EVENT_FIELDS,
  },
];

const valuesConfig = (data) => ({
  uuid: data.uuid,
  name: data.name || '',
  segment: (data.segment_ids ? data.segment_ids.map(String) : []),
  is_active: data.is_active || false,
  integrations: (data.channels ? data.channels.map((item) => item.integration) : []),
  recommendations_precision: data.recommendations_precision * 100 || 50,
  recommendations_source_ids: data.recommendations_source_ids || [],
  page: 1,
  ordering: ['-date_last_order'],
  type: data.type || 'segment',
  tags: getTagsFromFilters(data.filters, entityKeysToFields),
});

const customersConfig = (data) => ({
  page: data.page,
  ordering: data.ordering,
  segment: data.segment || [],
  tags: data.tags || [],
  type: data.type || AUDIENCE_TYPES.segment,
  recommendations_source_ids: data.recommendations_source_ids || [],
  recommendations_precision: (data.recommendations_precision || 50) / 100,
});

const precisionTypes = {
  low: {
    type: 'warning',
    text: MESSAGES.lower_accuracy,
  },
  good: {
    type: 'info',
    text: MESSAGES.good_accuracy,
  },
  high: {
    type: 'precise',
    text: MESSAGES.high_accuracy,
  },
};

const CustomAudience = () => {
  const { uuid } = useParams();
  const [channelsMap, setChannelsMap] = useState();
  const [values, setValues] = useState(valuesConfig({}));
  const [showCustomers, setShowCustomers] = useState(undefined);
  const [cancelCreateEdit, setCancelCreateEdit] = useState(undefined);
  const [deleteAudienceUuid, setDeleteAudienceUuid] = useState();
  const { push, location } = useRouter();
  const dispatch = useDispatch();
  const customerListTotalCount = useSelector(getCustomerListTotalCount);
  const isCustomerListLoading = useSelector(getCustomerListLoading);
  const recommendedProducts = useSelector(getProductRecommendations);
  const isRecommendedProductsLoading = useSelector(getProductRecommendationsIsLoading);
  const segmentsCount = useSelector(getSegmentsCount);
  const onEditSuccess = () => push(ROUTES.customAudiences);
  const [isAudienceLoading, setIsAudienceLoading] = useState(true);

  const handleTagsChange = (tags) => setValues({ ...values, tags });

  const {
    tags,
    fields,
    operators,
    addTag,
    removeTag,
    updateTag,
    setAllTags,
  } = tagHelper(null, handleTagsChange, searchEntityConfig, getCustomerFilterList);

  useEffect(() => {
    const populateChannels = async () => {
      const channelsList = await getChannels();
      setChannelsMap(keyBy(channelsList, 'uuid'));
    };

    populateChannels();
  }, []);

  const updateCustomerList = (newValues) => {
    const config = customersConfig(newValues);
    dispatch(fetchCustomerList(config));
    if (config.type === AUDIENCE_TYPES.segment) {
      dispatch(fetchProductRecommendations(config));
    }
  };

  const debounceUpdateCustomerList = useCallback(debounce(updateCustomerList, 1000), []);

  const handleExport = async () => {
    const config = customersConfig(values);
    const convertedConfig = {
      ...config,
      filters: {
        recommendations_source_ids: config.recommendations_source_ids,
        recommendations_precision: config.recommendations_precision,
      },
    };
    await exportCustomers(convertedConfig);
  };

  useEffect(() => {
    if (isAudienceLoading) return;
    debounceUpdateCustomerList(values);
  }, [
    values.segment, values.page, values.type,
    values.ordering, values.tags, values.recommendations_source_ids,
    values.recommendations_precision, values.page, values.ordering,
    isAudienceLoading,
  ]);

  const submitHandler = async (_, setErrors) => {
    const body = {
      name: values.name,
      segment_ids: values.segment,
      is_active: values.is_active,
      integrations: values.integrations,
      type: values.type,
      recommendations_source_ids: values.recommendations_source_ids,
      recommendations_precision: (values.recommendations_precision || 50) / 100,
      filters: prepareTagsAsFilterArray(values.tags),
    };

    try {
      if (values.uuid) {
        await updateCustomAudience(values.uuid, body);
      } else {
        await createCustomAudience(body);
      }
      onEditSuccess();
    } catch (error) {
      if (error && error.name) {
        setErrors({
          name: new ValidationError(error.name[0]).message,
        });
      }
    }
  };

  const { execute, status } = useAsync(submitHandler);
  const {
    handleSubmit,
    handleBlur,
    handleFocus,
    handleChange: handleFormChange,
    setValues: setFormValues,
    setTouchedAll,
    errors,
    touched,
  } = useForm(execute, validation, values);

  const imitateFormChange = (name, value) => handleFormChange({ target: { name, value } });

  const { createToast } = useToast();
  const handleSubmitClick = (e) => {
    const toastErrors = [errors.integrations, errors.recommendations_source_ids];
    toastErrors.filter(Boolean).forEach((error) =>
      createToast(
        MESSAGES.failed,
        error.header,
        'danger',
        'custom-audience-integration-error',
      ));

    handleSubmit(e);
  };

  const handleSourceIdsChange = (source_ids) => {
    setValues({ ...values, recommendations_source_ids: source_ids });
    imitateFormChange('recommendations_source_ids', source_ids);
  };

  const {
    selectedKeys,
    setSelectedKeys,
    handleSelect,
    handleDeselect,
    clearSelection,
  } = multiSelectHelper(handleSourceIdsChange);

  const toInternalStates = (audience) => {
    const convertedValues = valuesConfig(audience);
    setValues(convertedValues);
    setAllTags(convertedValues.tags);
    setSelectedKeys(convertedValues.recommendations_source_ids);
    setFormValues(convertedValues);
    setTouchedAll(convertedValues);
  };

  useEffect(() => {
    const getAudience = async () => {
      const audience = await getCustomAudience(uuid);
      toInternalStates(audience);
      dispatch(setAudienceName({ [audience.uuid]: audience.name }));
    };

    if (uuid) {
      setIsAudienceLoading(true);
      getAudience().finally(() => setIsAudienceLoading(false));
    } else {
      if (location.state) {
        toInternalStates(location.state);
      }
      setIsAudienceLoading(false);
    }
  }, [uuid]);

  const handleChange = (e) => {
    const name = { name: e.target.value };
    setValues((prevState) => ({
      ...prevState,
      ...name,
    }));
    handleFormChange(e);
  };

  const changeIntegrations = (integrations) => {
    setValues({
      ...values,
      integrations,
    });
    imitateFormChange('integrations', integrations);
  };

  const handleCheck = (value) => {
    const checkValue = { is_active: !value };
    setValues((prevState) => ({
      ...prevState,
      ...checkValue,
    }));
    return checkValue.is_active;
  };

  const selectSegmentHandler = (id, isSelected) => {
    const newSegments = !isSelected ? [...values.segment, id]
      : values.segment.filter((segmentId) => segmentId !== id);

    const newValues = { ...values, segment: newSegments };
    setValues(newValues);
  };

  const changePageOrdering = ({ page, ordering }) => {
    const newValues = {
      ...values,
      page: page || values.page,
      ordering: ordering || values.ordering,
    };
    setValues(newValues);
  };

  const handlePrecisionChange = (precision) =>
    setValues({ ...values, recommendations_precision: Math.max(precision, 1) });

  const onTypeChange = (newType) => {
    if (newType === values.type) return;
    setValues({
      ...values,
      type: newType,
      segment: [],
      tags: [],
      recommendations_source_ids: [],
    });
    clearSelection();
    imitateFormChange('type', newType);
    imitateFormChange('recommendations_source_ids', []);
  };

  let precisionType = precisionTypes.low;
  if (values.recommendations_precision < 65) precisionType = precisionTypes.good;
  if (values.recommendations_precision < 30) precisionType = precisionTypes.high;
  const hasPrecision = [AUDIENCE_TYPES.category, AUDIENCE_TYPES.product].includes(values.type);
  const hasRecommendedProducts = values.type === AUDIENCE_TYPES.segment;
  const confirmationDeactivation = status !== 'DISABLED';

  return (
    <>
      <div className="create-edit-audience">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={3} xs={12}>
                <Block
                  header={values.uuid
                    ? MESSAGES.edit_custom_audience
                    : MESSAGES.create_custom_audience}
                  postHeader={MESSAGES.activate_your_custom_data}
                  guide={GUIDES.NA1}
                  fillHeight
                  type="large-header"
                />
              </Grid>
              <Grid item lg={9} xs={12}>
                <Block fillHeight>
                  <Grid container spacing={2}>
                    <Grid item sm={5} xs={12}>
                      <div className="input-field-wrapper">
                        <span className="name">
                          {MESSAGES.name}
                        </span>
                        <Input
                          id="name"
                          name="name"
                          label={MESSAGES.create_activation_placeholder}
                          hasFloatLabel={false}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                          value={values.name}
                          error={errors.name}
                          touched={touched.name}
                          hasFeedback
                          isRequired
                        />
                        <span className="descriptive-text">
                          {MESSAGES.give_activation_descriptive_name}
                        </span>
                      </div>
                    </Grid>
                    <Grid item sm={7} xs={12}>
                      <Stack direction="row" spacing={2} justifyContent={{ sm: 'flex-end', xs: 'flex-start' }}>
                        <div className="active-field">
                          <div className="active-title">
                            {MESSAGES.status}
                          </div>
                          <div className="toggle-container">
                            <StatusSwitch
                              name={AUDIENCE_ITEMS.is_active}
                              checked={values.is_active}
                              onChange={() => handleCheck(values.is_active)}
                            />
                            <StatusIndicator
                              statuses={AUDIENCE_STATUSES_VALUES}
                              value={values.is_active ? AUDIENCE_STATUSES.ACTIVE.value
                                : AUDIENCE_STATUSES.INACTIVE.value}
                            />
                          </div>
                        </div>
                        {values.uuid && (
                          <IconButton
                            className="delete-icon"
                            icon={DeleteIcon}
                            type="danger"
                            size="medium"
                            onClick={() => setDeleteAudienceUuid(values.uuid)}
                          />
                        )}
                        <div className="save-wrapper">
                          <Button
                            type={BUTTON_TYPE.primary}
                            onClick={handleSubmitClick}
                          >
                            {MESSAGES.save}
                          </Button>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </Block>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={9} md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={5} xs={12}>
                <Block
                  header={MESSAGES.activation_type}
                  guide={GUIDES.NA2}
                >
                  <ContentLoader isLoading={isAudienceLoading}>
                    <AudienceTypeSelector
                      onKeySelect={handleSelect}
                      onKeyDeselect={handleDeselect}
                      selectedKeys={selectedKeys}
                      onTypeChange={onTypeChange}
                      onSelectedKeysChange={handleSourceIdsChange}
                      value={values.type}
                    />
                  </ContentLoader>
                </Block>
              </Grid>
              <Grid item xl={8} lg={7} xs={12}>
                <Stack spacing={2}>
                  {hasPrecision && (
                    <Block
                      postHeader={MESSAGES.recommended_audience}
                    >
                      <Grid container spacing={4}>
                        <Grid item sm={6} xs={12}>
                          <span className="recommended-audience-description">
                            <div dangerouslySetInnerHTML={{
                              __html: MESSAGES.recommended_audience_description,
                            }}
                            />
                          </span>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={2}>
                            <Slider
                              max={100}
                              value={values.recommendations_precision}
                              min={0}
                              percentage
                              marks={6}
                              label={MESSAGES.audience_size}
                              onChange={handlePrecisionChange}
                            />
                            <Box display="flex" justifyContent="end">
                              <Disclaimer
                                text={precisionType.text}
                                type={precisionType.type}
                              />
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Block>
                  )}
                  <Block
                    header={MESSAGES.filter_customers}
                    guide={GUIDES.NA4}
                  >
                    <Stack spacing={4}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                      >
                        <SearchEntity
                          config={searchEntityConfig}
                          ToggleComponent={ToggleSearchEntity}
                          selectedItems={tags}
                          onItemSelect={addTag}
                        />
                      </Stack>
                      <Grid container spacing={1}>
                        {tags && tags.map((tag) => (
                          <Grid key={tag.key} item>
                            <AdvancedFilterTag
                              tag={tag}
                              fields={fields}
                              operators={operators}
                              searchEntityConfig={searchEntityConfig}
                              onTagChange={(updatedTag) => updateTag(tag.key, updatedTag)}
                              onRemove={() => removeTag(tag.key)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Stack>
                  </Block>
                  <Block
                    header={MESSAGES.customers}
                    guide={GUIDES.NA3}
                  >
                    <Stack spacing={2}>
                      <CustomerListSegments
                        onSelect={selectSegmentHandler}
                        selectedSegments={values.segment}
                        popperPlacement="top"
                        segmentCounts={segmentsCount}
                      />
                      <>
                        <Divider />
                        <Box display="flex" alignItems="center">
                          <ContentLoader isLoading={isCustomerListLoading}>
                            <Grid
                              container
                              spacing={2}
                            >
                              <Grid item>
                                <Button
                                  type={BUTTON_TYPE.secondary}
                                  onClick={() => setShowCustomers(true)}
                                >
                                  {MESSAGES.show_customers}
                                </Button>
                              </Grid>
                              <Grid item>
                                <div className="show-customers__results">
                                  <span className="count">
                                    {NumberFormatted(customerListTotalCount)}
                                  </span>
                                  <span className="results">
                                    {(MESSAGES.customers).toLowerCase()}
                                  </span>
                                </div>
                              </Grid>
                              <Grid item sx={{ marginLeft: 'auto' }}>
                                <DataExport exportHandler={handleExport} />
                              </Grid>
                            </Grid>
                          </ContentLoader>
                        </Box>
                      </>
                    </Stack>
                  </Block>
                  {hasRecommendedProducts && (
                    <Block
                      header={MESSAGES.recommended_products}
                      postHeader={MESSAGES.yearly_performance}
                      guide={GUIDES.NA6}
                      className="recommended-products-wrapper"
                    >
                      <ContentLoader isLoading={isRecommendedProductsLoading}>
                        <RecommendedProducts products={recommendedProducts} />
                        {(!recommendedProducts || !recommendedProducts.length) && (
                          <Box display="flex" justifyContent="center">
                            <span className="empty-label">
                              {MESSAGES.no_results}
                            </span>
                          </Box>
                        )}
                      </ContentLoader>
                    </Block>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={12} xs={12}>
            <Stack spacing={2}>
              <Block
                header={MESSAGES.integrate_with_channels}
                guide={GUIDES.NA5}
                fillContentHeight
              >
                <AudienceChannels
                  channelsMap={channelsMap}
                  integrations={values.integrations}
                  integrationsChanged={changeIntegrations}
                />
              </Block>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <ConfirmationWindow
        danger={confirmationDeactivation}
        secondaryGhost
        requestStatus={status}
        forceBlocking={false}
        content={confirmationDeactivation ? MESSAGES.cancel_confirmation : null}
        open={cancelCreateEdit}
        onCancel={() => {
          setCancelCreateEdit(undefined);
        }}
        onConfirm={() => {
          setCancelCreateEdit(false);
          onEditSuccess();
        }}
      />
      <ShowCustomersTableWindow
        open={showCustomers}
        onClose={setShowCustomers}
        forceBlocking={!showCustomers}
        config={values}
        changeBaseConfig={changePageOrdering}
        customerListTotalCount={customerListTotalCount}
        withExport={false}
        openOnNewTab
      />
      <DeleteAudiencePreviewModal
        deleteAudienceUuid={deleteAudienceUuid}
        onClose={() => setDeleteAudienceUuid(null)}
        onSubmit={onEditSuccess}
      />
    </>
  );
};

export default CustomAudience;

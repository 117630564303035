import React from 'react';
import propTypes from 'prop-types';

import './styles.scss';
import PaymentCards from 'containers/PrivateApp/Payment/PaymentForm/PaymentCards';
import { AVAILABLE_CARDS } from 'constants/card';
import PaymentFormInput from 'containers/PrivateApp/Payment/PaymentForm/PaymentFormInput';
import CardNumber from 'containers/PrivateApp/Payment/PaymentForm/CardNumber';
import CardExpiry from 'containers/PrivateApp/Payment/PaymentForm/CardExpiry';
import CardCVCNumber from 'containers/PrivateApp/Payment/PaymentForm/CardCVCNumber';
import useForm from 'hooks/useForm';
import validation from 'containers/PrivateApp/Payment/PaymentForm/paymentValidation';
import usePrompt from 'hooks/usePrompt';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import Feedback from 'components/common/Input/Feedback';
import PromotionCode from '../PromotionCode';
import { MESSAGES } from 'constants/messages';

const CardForm = ({
  onSubmit,
  hasPrompt,
  hasPromotionCode,
  setSubmitDisabled,
  children,
}) => {
  const {
    handleSubmit, handleChange, handleBlur, values, errors, touched,
  } = useForm(
    onSubmit,
    validation,
  );

  const {
    isBlocking,
    onCancel: onCancelPrompt,
    onConfirm: onConfirmPrompt,
  } = usePrompt(hasPrompt);

  return (
    <>
      <ConfirmationWindow
        content={MESSAGES.leave_the_page}
        open={isBlocking}
        onCancel={onCancelPrompt}
        onConfirm={onConfirmPrompt}
        forceBlocking={false}
        inWorkArea
      />
      <form onSubmit={handleSubmit} className="card-form">
        <div className="payment-form__card card">
          <PaymentCards cards={AVAILABLE_CARDS} brand={values.cardNumber?.brand} />
          <div className="card-form__container">
            {errors.detail && (
              <div className="form__error">
                <Feedback isCenter hint={{ header: errors.detail }} />
              </div>
            )}
            <div className="card-form__field card-form__card-name">
              <PaymentFormInput
                id="cardName"
                name="cardName"
                placeholder={MESSAGES.john_nicholas_doe}
                label={MESSAGES.card_holders_name}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="cc-name"
                value={values.cardName}
                error={errors.cardName}
                touched={touched.cardName}
              />
            </div>
            <div className="card-form__field card-form__card-number">
              <CardNumber
                id="cardNumber"
                name="cardNumber"
                placeholder="**** **** **** ****"
                label={MESSAGES.card_number}
                autocomplete="cc-csc"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.cardNumber}
                touched={touched.cardNumber}
                hasFeedback
                isRequired
              />
            </div>
            <div className="card-form__field card-form__other">
              <CardExpiry
                id="cardExpiry"
                name="cardExpiry"
                label={MESSAGES.expiration_date}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.cardExpiry}
                touched={touched.cardExpiry}
                hasFeedback
                isRequired
              />
              <div className="card-form__CVC">
                <CardCVCNumber
                  id="cardCVC"
                  name="cardCVC"
                  label={MESSAGES.cvc}
                  placeholder="***"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.cardCVC}
                  touched={touched.cardCVC}
                  hasHelp
                  hasFeedback
                  isRequired
                />
              </div>
            </div>
            {hasPromotionCode && (
              <div className="card-form__field card-form__promotion-code">
                <PromotionCode
                  id="promotionCode"
                  name="promotionCode"
                  placeholder=""
                  label={MESSAGES.coupon_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.promotionCode}
                  error={errors.promotionCode}
                  touched={touched.promotionCode}
                  hasFeedback
                  isRequired
                  autoFocus
                  setSubmitDisabled={setSubmitDisabled}
                />
              </div>
            )}
            <div className="card-form__actions">
              {children}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

CardForm.propTypes = {
  children: propTypes.node.isRequired,
  onSubmit: propTypes.func.isRequired,
  hasPrompt: propTypes.bool,
  hasPromotionCode: propTypes.bool.isRequired,
  setSubmitDisabled: propTypes.func.isRequired,
};

CardForm.defaultProps = {
  hasPrompt: false,
};

export default CardForm;
